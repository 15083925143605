.each-slide {
  width: 100%;
  position: relative;
}

.each-slide > div {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio for Landscape */
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

@media (max-aspect-ratio: 4/3) {
  .each-slide > div {
    padding-top: 75%; 
  }
}
.hh{
  width: 60%;
  margin: auto;
}

@media (max-width: 900px) {
  .each-slide > div {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio for Landscape */
  }
  .hh{
    width: 100%;
  }
}




/* body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  } */
  
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #000 */
  }
  
  .school-info {
    text-align: center;
    line-height: 1.9;
  }
  
  .school-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .address {
    font-size: 16px;
    margin-top: 10px;
    border-bottom: double;
  }
  
  .certificate {
    margin-top: 30px;
    /* border: 1px solid #000; */
    padding: 20px;
  }
  
  .certificate-header {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .content1 {
    margin-top: 20px;
  }
  
  .serial-no,
  .gr-no {
    font-weight: bold;
  }
  
  .student-info {
    margin-top: 20px;
  }
  
  .print-footer {
    margin-top: 40px;
    text-align: right;
  }
  
  .date,
  .signature {
    font-weight: bold;
  }
  
  .signature {
    margin-top: 1rem;
  }
  